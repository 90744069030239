import { FormQuestionType } from '../../models/Form';
import { AttachmentAsync } from './AttachmentAsync';
import { CascadingDropdown } from './cascading-dropdown';
import { CmdbDropdown } from './CmdbDropdown';
import { Date } from './Date';
import { DateTime } from './DateTime';
import { Dropdown } from './Dropdown';
import { DropdownMultiselect } from './DropdownMultiselect';
import { LongText } from './LongText';
import { MultiChoice } from './MultiChoice';
import { Number } from './Number';
import { Paragraph } from './Paragraph';
import { RichTextParagraph } from './RichTextParagraph';
import { ShortText } from './ShortText';
import { SingleChoice } from './SingleChoice';
import { Time } from './Time';
import { UrlText } from './UrlText';
import { UserPicker } from './UserPicker';
export const defaultQuestionTypeRendererMap = {
  [FormQuestionType.TextShort]: ShortText,
  [FormQuestionType.TextLong]: LongText,
  [FormQuestionType.TextEmail]: LongText,
  [FormQuestionType.TextUrl]: UrlText,
  [FormQuestionType.TextParagraph]: Paragraph,
  [FormQuestionType.RichTextParagraph]: RichTextParagraph,
  [FormQuestionType.Number]: Number,
  [FormQuestionType.DateTime]: DateTime,
  [FormQuestionType.Date]: Date,
  [FormQuestionType.Time]: Time,
  [FormQuestionType.ChoiceCascading]: CascadingDropdown,
  [FormQuestionType.ChoiceSingle]: SingleChoice,
  [FormQuestionType.ChoiceMultiple]: MultiChoice,
  [FormQuestionType.ChoiceDropdown]: Dropdown,
  [FormQuestionType.ChoiceDropdownMultiple]: DropdownMultiselect,
  [FormQuestionType.UserSingle]: UserPicker,
  [FormQuestionType.UserMultiple]: UserPicker,
  [FormQuestionType.Attachment]: AttachmentAsync,
  [FormQuestionType.CmdbObject]: CmdbDropdown
};