// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N20, N70, N800, R400 } from '@atlaskit/theme/colors';
const textFieldDisabledStyles = `
  min-height: 36px;
  border-style: solid;
  border-color: ${"var(--ds-border-disabled, #DFE1E6)"};
  border-radius: 3px;
  border-width: 2px;
  background-color: ${`var(--ds-background-accent-gray-subtlest, ${N20})`};
  cursor: default;
  color: ${`var(--ds-text-disabled, ${N70})`};

  > div {
    padding: 8px 6px;
    line-height: normal
  }
`;
const choiceDisabledStyles = `
  > div {
    border-style: none;
    border-color: ${"var(--ds-border-disabled, #DFE1E6)"};
    border-radius: 3px;
    border-width: 2px;
    background-color: none;
    cursor: default;
    pointer-events: none;
  }
`;
const choiceInvalidStyles = `
  > div {
    border-style: none;
    border-color: ${"var(--ds-border-danger, #FF5630;)"}
    border-radius: 3px;
    border-width: 2px;
    cursor: default;
  }
`;
const choiceViewStyles = `
  span {
    color: ${`var(--ds-text, ${N800})`};
  }
`;
export const invalidFieldContainerStyles = `
  border-style: solid;
  border-color: ${`var(--ds-border-danger, ${R400})`};
  border-radius: 3px;
  border-width: 2px;
  cursor: default;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const FieldDisabledWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div': {
    borderStyle: 'solid',
    borderColor: "var(--ds-border-disabled, #DFE1E6)",
    borderRadius: 3,
    borderWidth: 2,
    backgroundColor: `var(--ds-background-accent-gray-subtlest, ${N20})`,
    cursor: 'default',
    pointerEvents: 'none',
    color: `var(--ds-text-disabled, ${N70})`
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '*': {
    color: "var(--ds-text, #172B4D !important)",
    cursor: 'default'
  }
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ChoiceFieldWrapper = styled('div')(
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
props => props.isDisabled && choiceDisabledStyles,
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
props => props.isInvalid && choiceInvalidStyles,
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
props => props.isView && choiceViewStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const TextfieldDisabledWrapper = styled.div(textFieldDisabledStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ShortTextFieldWrapper = styled('div')({
  maxWidth: '240px'
},
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
props => props.isDisabled && textFieldDisabledStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ChoicePlaceholder = styled.div({
  margin: "var(--ds-space-500, 40px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelWrapper = styled.div({
  display: 'flex'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorWrapper = styled.div({
  height: '16px',
  marginTop: "var(--ds-space-050, 4px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectErrorContainer = styled.div(
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
props => props.isInvalid && invalidFieldContainerStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ShortFieldWrapper = styled.div({
  maxWidth: '160px'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewWrapper = styled.div({
  font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  color: `var(--ds-text, ${N800})`
});